












import {
  computed,
  defineComponent,
} from '@nuxtjs/composition-api';

import { SimpleImage } from '~/components/common/SimpleImage';
import { MODAL_NAMES, SimpleModal } from '~/components/common/SimpleModal';
import { useUiState } from '~/composables';

export default defineComponent({
  name: 'LightBoxImageModal',
  components: {
    SimpleImage,
    SimpleModal,
  },
  setup() {
    const { modal } = useUiState();

    return {
      modalProps: computed(() => modal.value.props?.image || {}),
      name: MODAL_NAMES.lightboxImage,
    };
  },
});
